@import url("https://cdn.syncfusion.com/ej2/material.css");

.datePicker {
    margin-left: '5vw';
    display: 'inline-block';
    background-color: white;
    color: blue;
  }
  .datePicker.selected {
      background-color: blue;
      color: white;
  }